import axios from "axios";
import store from "@/store";

/**
 * Request to BigQuery to check if a user has viewed the
 * pages update notification
 * @param {String} path The path of the current page visible
 * @param {String} notificationID The ID of the notification to query
 * @returns If the user needs notifying or not
 */
export function hasBeenNotified(path, notificationID) {
  return new Promise((resolve, reject) => {
    const { token } = store.getters["user/userInfo"];

    axios
      .get("/api/private/user/notify", {
        params: { path, notificationID },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        resolve(response.data.needsNotifying);
      })
      .catch((error) => {
        console.error(error);
      });
  });
}

/**
 * Request to BigQuery to insert that the user has seen the update notification
 * @param {String} path The path of the current page visible
 * @param {String} notificationID The ID of the notification to query
 * @returns A resolved promise
 */
export function notificationSeen(path, notificationID) {
  return new Promise((resolve, reject) => {
    const { token } = store.getters["user/userInfo"];

    axios
      .post(
        "/api/private/user/notified",
        { path, notificationID },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.error(error);
      });
  });
}
