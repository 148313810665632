import { captchaAssessment } from "@/loxAPI/reCAPTCHA/index.js";
import { ref, computed } from "vue";
import i18n from "../Translations/i18n";

export function useLocale() {
  const setLocale = (locale) => {
    i18n.global.locale = locale;
    localStorage.setItem("locale", locale);
  };
  return { setLocale };
}

export function useCurrencyFormatter() {
  const currencyFormatter = (value) => {
    return value?.toLocaleString("en-US", {
      style: "currency",
      currency: "EUR",
    });
  };
  return { currencyFormatter };
}

export function useValidation() {
  const validEmail = (email) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };
  const validURL = (url) => {
    const regex =
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
    return regex.test(url);
  };
  return { validEmail, validURL };
}
export function usePasswordValidation() {
  const passwordLength = (password) => {
    return password.length >= 8;
  };
  const passwordHasUpperCase = (password) => {
    const re = /(?=.*[A-Z])/;
    return re.test(password);
  };
  const passwordHasLowerCase = (password) => {
    const re = /(?=.*[a-z])/;
    return re.test(password);
  };
  const passwordHasDigit = (password) => {
    const re = /(?=.*\d)/;
    return re.test(password);
  };
  const passwordValidity = (password) => {
    return (
      passwordLength(password) &&
      passwordHasLowerCase(password) &&
      passwordHasUpperCase(password) &&
      passwordHasDigit(password)
    );
  };
  return {
    passwordLength,
    passwordHasUpperCase,
    passwordHasLowerCase,
    passwordHasDigit,
    passwordValidity,
  };
}

export function useLoxUserValidation() {
  const isLoxUser = (email) => {
    const matchRegEx = /@loxsolution/g;
    return email.match(matchRegEx);
  };
  return { isLoxUser };
}

export function useCaptcha() {
  const verifyUserInteraction = (event, action) => {
    return new Promise((resolve, reject) => {
      if (process.env.VUE_APP_DATABASE_SCOPE === "main") {
        event.preventDefault();
        grecaptcha.enterprise.ready(async () => {
          const token = await grecaptcha.enterprise.execute(
            process.env.VUE_APP_GRECAPTCHA,
            { action }
          );
          // This token must be validated by creating an assessment.
          // See https://cloud.google.com/recaptcha-enterprise/docs/create-assessment
          // After the token is generated, send the reCAPTCHA token to your backend and create an assessment within two minutes.
          const interactionVerified = await captchaAssessment(token, action);
          resolve(interactionVerified);
        });
      } else {
        resolve(true);
      }
    });
  };
  return { verifyUserInteraction };
}

export function useSnubInjections() {
  const snubInjections = (possibleInjection) => {
    /*eslint-disable */
    const injectionKeys = {
      "&amp": "&",
      "&gt": ">",
      "&lt": "<",
      "&quot": `"`,
      "&#039": "'",
    };
    for (const [key, value] of Object.entries(injectionKeys)) {
      possibleInjection = possibleInjection.replace(value, key);
    }
    return possibleInjection;
  };
  const reverseSnub = (string) => {
    if (!string) {
      return string;
    }
    /*eslint-disable */
    const conversionKeys = {
      "&amp": "&",
      "&gt": ">",
      "&lt": "<",
      "&quot": `"`,
      "&#039": "'",
    };
    for (const [key, value] of Object.entries(conversionKeys)) {
      string = string.replaceAll(key, value);
    }
    return string;
  };
  return { snubInjections, reverseSnub };
}
