export const numberFormats = {
  en: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
  },
  fr: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
  },
};
