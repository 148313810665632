import "@/Global/Styles/_cards.scss";
export default {
  name: "LoadingCard",
  data() {
    return {
      my_image: new Image(),
      src: require("@/Assets/images/loader-80x80.gif")
    };
  },
  mounted() {
    // Here we make sure that the animation always starts from the beginning
    // upon rendering.
    this.my_image.src = this.src;
    document.getElementById("loader").src = this.src;
  }
};