import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";

import modules from "./modules";

const store = createStore({
  modules,
  plugins: [new VuexPersistence().plugin],
  strict: process.env.NODE_ENV !== "production",
});

export default store;
