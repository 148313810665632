import axios from "axios";
import store from "@/store";
import { messages } from "@/Global/Components/KitForUI/PopMessages/messages.js";
import { featureFetcher } from "@/Platform/Login/Requests";

export function insertPageVisit(pageData) {
  return new Promise((resolve, reject) => {
    const { token } = store.getters["user/userInfo"];

    axios
      .post(
        "/api/private/user/page/visit",
        {
          ...pageData,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.error(error.message);
        messages.generalError();
        reject(error);
      });
  });
}

export function actionTour(label, route) {
  return new Promise((resolve, reject) => {
    const { token } = store.getters["user/userInfo"];

    axios
      .post(
        "/api/private/features/finished",
        {
          label,
          route,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(async (response) => {
        if (response.status === 200) {
          await featureFetcher();
        }

        resolve();
      })
      .catch((error) => {
        console.error(error.message);
        messages.generalError();
        reject(error);
      });
  });
}
