import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-52db8d0e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "title-container"
};
const _hoisted_2 = {
  class: "body-container"
};
import { notificationSeen, hasBeenNotified } from "@/loxAPI/UpdateNotification/index.js";
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
export default {
  __name: 'NewUpdateInformation',
  setup(__props) {
    const route = useRoute();
    const timerID = ref(null);
    const shouldBeVisible = ref(false);
    const content = ref([{
      key: "Home",
      notificationID: "homeComingSoon",
      title: "platform.newUpdates.home.comingSoon.title",
      text: "platform.newUpdates.home.comingSoon.text",
      active: false
    }, {
      key: "Home",
      notificationID: "homeLanded",
      title: "platform.newUpdates.home.landed.title",
      text: "platform.newUpdates.home.landed.text",
      active: true
    }, {
      key: "Claim Center - Data Needed",
      notificationID: "uploadInvestigationComingSoon",
      title: "platform.newUpdates.dataNeeded.comingSoon.title",
      text: "platform.newUpdates.dataNeeded.comingSoon.text",
      active: false
    }, {
      key: "Claim Center - Data Needed",
      notificationID: "dataNeededLanded",
      title: "platform.newUpdates.dataNeeded.landed.title",
      text: "platform.newUpdates.dataNeeded.landed.text",
      active: true
    }, {
      key: "Claim Center - Upload Investigation",
      notificationID: "uploadInvestigationLanded",
      title: "platform.newUpdates.dataNeeded.landed.title",
      text: "platform.newUpdates.dataNeeded.landed.text",
      active: true
    }]);

    // The current route name
    const routeName = computed(() => route.name);
    // If the key matches the current route name
    // set the notification as the active content
    // to display
    const activeContent = computed(() => content.value.filter(object => object.key === routeName.value && object.active)[0]);

    // Upon the component mounting we check if the
    // user has viewed the notification for this route
    onMounted(async () => {
      const needsNotifying = await hasBeenNotified(route.path, activeContent.value.notificationID);
      // If the user needs notifying start the count
      // down to slide the component into view
      if (needsNotifying) {
        shouldBeVisible.value = needsNotifying;
        startCountDown("slideIn");
      }
    });

    // Start the countdown
    // If a count down has started clear it and start a new one
    // Timing and functionality set for different actions:
    // - slideIn functionality runs after 0.5secs and makes the component visible
    // - slideOut (default) functionality runs after 5secs and makes the component hidden
    //   it then makes a request to update that the user has seen the component
    const startCountDown = direction => {
      // Clear timer if present
      if (timerID.value) {
        clearTimeout(timerID.value);
        timerID.value = null;
      }
      switch (direction) {
        // slideIn action
        case "slideIn":
          timerID.value = setTimeout(() => {
            const banner = document.getElementById("update-notification");
            banner.style.right = "32px";
            banner.style.opacity = 1;
            startCountDown("slideOut");
          }, 500);
          break;
        // slideOut action
        default:
          timerID.value = setTimeout(async () => {
            const banner = document.getElementById("update-notification");
            banner.style.right = "-400px";
            banner.style.opacity = 0;
            await notificationSeen(route.path, activeContent.value.notificationID);
          }, 10000);
      }
    };
    // Start the count down to slide the notification out of view
    const startToSlideOut = () => {
      startCountDown("slideOut");
    };
    // Stop the current set timer ( happens when a user hovers on the component )
    const stopSlideOutTimer = () => {
      clearTimeout(timerID.value);
      timerID.value = null;
    };
    return (_ctx, _cache) => {
      return shouldBeVisible.value ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: "update-notification",
        class: "card-body",
        onMouseover: stopSlideOutTimer,
        onMouseleave: startToSlideOut
      }, [_createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t(activeContent.value.title)), 1), _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t(activeContent.value.text)), 1)], 32)) : _createCommentVNode("", true);
    };
  }
};