import axios from "axios";
import store from "@/store";
import { messages } from "@/Global/Components/KitForUI/PopMessages/messages.js";

export function validateSuperToken(token) {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/public/user/verify/super_token", {
        params: { token },
      })
      .then((result) => {
        resolve(result.data.validated);
      })
      .catch((error) => {
        console.error(error);
        messages.generalError();
        reject(error);
      });
  });
}

export function removeSuperToken(token) {
  return new Promise((resolve, reject) => {
    axios
      .put("/api/public/user/verified/super_token", {
        token,
      })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.error(error);
        messages.generalError();
        reject(error);
      });
  });
}

export function refreshJwtToken() {
  return new Promise((resolve, reject) => {
    const { token } = store.getters["user/userInfo"];

    axios
      .post("/api/public/user/refresh", { token })
      .then((response) => {
        const token = response.data.token;

        store.dispatch("user/updateUserToken", { token });
        store.dispatch("user/updateUserFromToken");

        resolve();
      })
      .catch((error) => {
        console.error(error);

        if (error.response.status === 403) {
          messages.reAuthenticateUser();
        } else {
          messages.generalError();
        }

        reject(error);
      });
  });
}

export function decryptPassword(encryptedPassword) {
  return new Promise((resolve, reject) => {
    const { token } = store.getters["user/userInfo"];

    axios
      .get("/api/private/user/decrypt", {
        params: { encryptedPassword },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        resolve(response.data.decryptedPassword);
      })
      .catch((error) => {
        console.error(error);
        messages.generalError();
        reject(error);
      });
  });
}
