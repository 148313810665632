export const mutations = {
  SWITCH_ACTIVE_VIEW(state, payload) {
    state.claimCenter.activeView = payload;
  },
  FILTERED_DATA(state, payload) {
    state.activeFilterCount += payload.filter_count;
  },
  REFUNDS_FILTER_DATE(state, payload) {
    state.claimCenter.activeDateRange = payload.date;
  },
  SET_REFUNDS_DATE_RANGE(state, payload) {
    state.claimCenter = {
      ...state.claimCenter,
      dateRange: {
        month: payload.month,
        week: payload.week,
      },
    };
  },
  SWITCH_COLUMN_TOGGLE(state) {
    state.claimCenter.columnToggle = !state.claimCenter.columnToggle;
  },
  SET_REFUNDS_TABLE_FIELDS(state, payload) {
    state.claimCenter.columns = payload.columns;
  },
  SET_ACTIVE_FILTERS(state, payload) {
    if (!(payload.database_name in state.claimCenter.activeFilters)) {
      state.claimCenter = {
        ...state.claimCenter,
        activeFilterCount: state.claimCenter.activeFilterCount + 1,
        activeFilters: {
          ...state.claimCenter.activeFilters,
          [payload.database_name]: [payload.filter],
        },
      };
    } else {
      if (payload.singular) {
        state.claimCenter = {
          ...state.claimCenter,
          activeFilters: {
            ...state.claimCenter.activeFilters,
            [payload.database_name]: [payload.filter],
          },
        };
      } else {
        state.claimCenter = {
          ...state.claimCenter,
          activeFilters: {
            ...state.claimCenter.activeFilters,
            [payload.database_name]: [
              ...state.claimCenter.activeFilters[payload.database_name],
              payload.filter,
            ],
          },
        };
      }
    }
  },
  REMOVE_ACTIVE_FILTERS(state, payload) {
    const neededFilter = state.claimCenter.activeFilters[payload.database_name];
    if (!neededFilter) {
      return;
    }

    if (neededFilter.length === 1) {
      this.SET_ALL_ACTIVE_FILTERS(state, payload);
    } else {
      state.claimCenter = {
        ...state.claimCenter,
        activeFilters: {
          ...state.claimCenter.activeFilters,
          [payload.database_name]: neededFilter.filter(
            (filter) => filter !== payload.filter
          ),
        },
      };
    }
  },
  SET_ALL_ACTIVE_FILTERS(state, payload) {
    delete state.claimCenter.activeFilters[payload.database_name];
    state.claimCenter.activeFilterCount -= 1;
  },
  RESET_FILTERS(state) {
    state.claimCenter = {
      ...state.claimCenter,
      activeFilters: {},
      filtered: false,
      activeFilterCount: 0,
    };
  },
  TOGGLE_FILTERING(state, payload) {
    state.claimCenter.filtered = payload;
  },
  SET_REFUNDS_CLAIMED_BY(state, payload) {
    state.claimCenter.claimedBy = payload;
  },
  SWITCH_REFUNDS_CLAIMS(state, payload) {
    state.claimCenter.claimToggle = payload;
  },
};
