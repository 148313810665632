export const mutations = {
  SET_ADMIN_COMPANIES(state, payload) {
    state.adminPanel.companies = payload.companies;
  },
  SET_ADMIN_CREDENTIALS(state, payload) {
    state.adminPanel.credentials = payload.credentials;
  },
  SET_ADMIN_DOCUMENTS(state, payload) {
    state.adminPanel.documents = payload.documents;
  },
  SET_ADMIN_MEMBERS(state, payload) {
    state.adminPanel.companyMembers = payload.members;
  },
  SET_LOX_INVOICES(state, payload) {
    state.adminPanel.loxInvoices = payload.invoices;
  },
  SET_ADMIN_USERS(state, payload) {
    state.adminPanel.users = payload.users;
  },
  SET_ADMIN_GENERAL_FORM(state, payload) {
    state.adminPanel.generalForm = payload.form;
  },
  REMOVE_ITEM_GENERAL_FORM(state, payload) {
    state.adminPanel.generalForm[payload.variable] = payload.newValue;
  },
  SET_ADMIN_CASES(state, payload) {
    state.adminPanel.caseStudies = payload.studies;
  },
  SET_ADMIN_INVOICE_DETAILS(state, payload) {
    state.adminPanel.invoiceDetails = payload.details;
  },
  SET_ADMIN_API_CONFIG(state, payload) {
    state.adminPanel.apiConfig = payload.config;
  },
};
