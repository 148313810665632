export const state = {
  // We don't store the company and email in the local storage for safety reasons.
  // They will be retrieve thanks to the token.

  // Replace in order to remove quotes from string
  carrier: [],
  country_code_receiver: [],
  country_code_sender: [],
  state: [],
  reason_refund: [],
  service_level: [],
  stage: [],
  owners: [],
  claim_source: [],
};
