export const actions = {
  setLostPackages({ commit }, payload) {
    commit("SET_LOST_PACKAGES", payload);
  },
  setLostPackagesTotal({ commit }, payload) {
    commit("SET_LOST_PACKAGES_TOTAL", payload);
  },
  setLostDataPage({ commit }) {
    commit("SET_LOST_DATA_PAGE");
  },
  setApiConfig({ commit }, payload) {
    commit("SET_API_CONFIG", payload);
  },
  setLostInvoices({ commit }, payload) {
    commit("SET_LOST_INVOICES", payload);
  },
  setLostDataActivity({ commit }, payload) {
    commit("SET_LOST_DATA_ACTIVITY", payload);
  },
};
