import "core-js/modules/es.array.push.js";
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue";
import { ref, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import Button from "./Button.vue";
import { actionTour } from "@/loxAPI/Activity/index.js";
export default {
  __name: 'TourCard',
  props: {
    cards: {
      type: Array,
      required: true
    },
    tour: {
      type: Object,
      default: undefined
    }
  },
  emits: ["tourFinished"],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const activeIndex = ref(0);
    const top = ref(null);
    const left = ref(null);
    const transform = ref(null);
    const activeCard = computed(() => props.cards[activeIndex.value]);
    const activeRoute = computed(() => route.path);
    const arrowPosition = () => {
      switch (activeCard.value.arrowPosition) {
        case "left top":
          return {
            top: "1em",
            left: "-1.5em"
          };
        case "top right":
          return {
            top: "-1.5em",
            right: "1em"
          };
        case "right top":
          return {
            top: "1em",
            right: "-1.5em"
          };
      }
    };
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const emit = __emit;
    const indentifyUserAction = button => {
      switch (button.key) {
        case "Next":
          return next();
        case "Skip":
          return complete("skipped");
        case "Redirect":
          return redirect(button.route);
        default:
          return complete("completed");
      }
    };
    const next = () => {
      activeIndex.value += 1;
    };
    const complete = async label => {
      store.dispatch("layout/disengage_feature_tour");
      emit("tourFinished");
      await actionTour(label, activeRoute.value);
    };
    const redirect = route => {
      activeIndex.value = 0;
      top.value = null;
      left.value = null;
      transform.value = null;
      router.push(route);
    };
    const sidebarTour = positions => {
      if (!positions.top && positions.bottom === positions.height) {
        top.value = `${positions.height / 4}px`;
      } else {
        top.value = `${positions.top - 12}px`;
      }
      left.value = `${positions.width + 48}px`;
      transform.value = "none";
    };
    const topbarTour = positions => {
      top.value = `${positions.top + 70}px`;
      left.value = `${positions.left - 295}px`;
    };
    const tableTour = (id, positions) => {
      const topNumber = id === "savedAmount-0" ? 0 : 40;
      top.value = `${positions.top + topNumber}px`;
      left.value = `${positions.left - 250}px`;
      transform.value = "none";
    };
    const carriersTour = positions => {
      if (activeCard.value.id !== "credential-0") {
        top.value = `${positions.top - 20}px`;
        left.value = `${positions.left + 170}px`;
      } else {
        top.value = `${positions.top - 25}px`;
        left.value = `${positions.left - 310}px`;
      }
      transform.value = "none";
    };
    watch(activeIndex, () => {
      try {
        if ("id" in activeCard.value && activeIndex.value) {
          const featureToHighlight = document.getElementById(activeCard.value.id);
          const positions = featureToHighlight.getBoundingClientRect();
          switch (activeCard.value.id) {
            case "Sidebar":
              return sidebarTour(positions);
            case "IC-MyInvoices":
              return sidebarTour(positions);
            case "TopbarDropdown":
              return topbarTour(positions);
            case "MoreIcon-0":
              return tableTour(activeCard.value.id, positions);
            case "SavedAmount-0":
              return tableTour(activeCard.value.id, positions);
            case "AddNewCarrier":
              return carriersTour(positions);
            case "Carrier-0":
              return carriersTour(positions);
            case "Credential-0":
              return carriersTour(positions);
          }
        }
      } catch (error) {
        console.error(error);
      }
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: "modal-container card-body focal-point",
        style: _normalizeStyle({
          top: top.value,
          left: left.value,
          transform: transform.value
        })
      }, [_createElementVNode("div", {
        class: _normalizeClass(["arrow", activeCard.value.arrowDirection]),
        style: _normalizeStyle(arrowPosition())
      }, null, 6), _createElementVNode("div", {
        class: _normalizeClass(["title-container", activeCard.value.title.class])
      }, _toDisplayString(_ctx.$t(activeCard.value.title.text)), 3), _createElementVNode("div", {
        class: _normalizeClass(["body-container", activeCard.value.body.class])
      }, _toDisplayString(_ctx.$t(activeCard.value.body.text)), 3), _createElementVNode("div", {
        class: _normalizeClass(["footer-container", activeCard.value.footer.class])
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activeCard.value.footer.buttons, button => {
        return _openBlock(), _createElementBlock("div", {
          class: "button-container",
          key: button.key
        }, [_createVNode(Button, {
          label: button.label,
          clicked: indentifyUserAction.bind(null, button),
          buttonClass: button.class
        }, null, 8, ["label", "clicked", "buttonClass"])]);
      }), 128))], 2)], 4);
    };
  }
};