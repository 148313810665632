export const actions = {
  setAdminCompanies({ commit }, payload) {
    commit("SET_ADMIN_COMPANIES", payload);
  },
  setAdminCredentials({ commit }, payload) {
    commit("SET_ADMIN_CREDENTIALS", payload);
  },
  setAdminDocuments({ commit }, payload) {
    commit("SET_ADMIN_DOCUMENTS", payload);
  },
  setAdminMembers({ commit }, payload) {
    commit("SET_ADMIN_MEMBERS", payload);
  },
  setLoxInvoices({ commit }, payload) {
    commit("SET_LOX_INVOICES", payload);
  },
  setAdminUsers({ commit }, payload) {
    commit("SET_ADMIN_USERS", payload);
  },
  setAdminGeneralForm({ commit }, payload) {
    commit("SET_ADMIN_GENERAL_FORM", payload);
  },
  setAdminCases({ commit }, payload) {
    commit("SET_ADMIN_CASES", payload);
  },
  setAdminInvoiceDetails({ commit }, payload) {
    commit("SET_ADMIN_INVOICE_DETAILS", payload);
  },
  setAdminApiConfig({ commit }, payload) {
    commit("SET_ADMIN_API_CONFIG", payload);
  },
  removeItemGeneralForm({ commit }, payload) {
    commit("REMOVE_ITEM_GENERAL_FORM", payload);
  },
};
