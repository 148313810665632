export const state = {
  activeView: "Overview",
  claimToggle: false,
  activeDateRange: null,
  filtered: false,
  columnToggle: false,
  columns: null,
  dateRange: {
    month: null,
    week: null,
  },
  claimedBy: "All",
};
