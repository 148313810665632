import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { state } from "./state";

import { packageInformation } from "../Tabs/ActionRequired/Store/index.js";
import { myClaims } from "../Tabs/StartAnInvestigation/Store/index.js";

export const claimCenter = {
  state: {
    ...state,
    packageInformation: packageInformation.state,
    myClaims: myClaims.state,
  },
  mutations: {
    ...mutations,
    ...packageInformation.mutations,
    ...myClaims.mutations,
  },
  getters: {
    ...getters,
    ...packageInformation.getters,
  },
  actions: {
    ...actions,
    ...packageInformation.actions,
    ...myClaims.actions,
  },
};
