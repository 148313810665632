export const state = {
  // We don't store the company and email in the local storage for safety reasons.
  // They will be retrieve thanks to the token.

  // Replace in order to remove quotes from string
  filters: [],
};

export const mutations = {
  SET_DATA(state, payload) {
    state.filters = payload;
  },
  NO_DATA(state) {
    state.filters = [];
  },
};

export const getters = {
  dataSent(state) {
    return state.filters;
  },
};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  send_filters({ commit }, payload) {
    commit("SET_DATA", payload);
  },
  logout({ commit }) {
    commit("NO_DATA");
  },
};
