export const actions = {
  switchActiveView({ commit }, payload) {
    commit("SWITCH_ACTIVE_VIEW", payload.view);
  },
  filteredData({ commit }, payload) {
    commit("FILTERED_DATA", payload);
  },
  filterRefundsDate({ commit }, payload) {
    commit("REFUNDS_FILTER_DATE", payload);
  },
  switchColumnToggle({ commit }) {
    commit("SWITCH_COLUMN_TOGGLE");
  },
  setRefundsDateRange({ commit }, payload) {
    commit("SET_REFUNDS_DATE_RANGE", payload);
  },
  setRefundsFilters({ commit }, payload) {
    commit("SET_ACTIVE_FILTERS", payload);
  },
  removeRefundsFilters({ commit }, payload) {
    commit("REMOVE_ACTIVE_FILTERS", payload);
  },
  setAllRefundsFilters({ commit }, payload) {
    commit("SET_ALL_ACTIVE_FILTERS", payload);
  },
  toggleRefundsFiltering({ commit }, payload) {
    commit("TOGGLE_FILTERING", payload);
  },
  resetRefundsFilters({ commit }) {
    commit("RESET_FILTERS");
  },
  setRefundsClaimedBy({ commit }, payload) {
    commit("SET_REFUNDS_CLAIMED_BY", payload);
  },
  switchRefundsClaims({ commit }, payload) {
    commit("SWITCH_REFUNDS_CLAIMS", payload);
  },
};
