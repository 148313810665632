import axios from "axios";
import store from "@/store";
import Moment from "moment";
import { refreshJwtToken } from "@/loxAPI/Authentication/index.js";
import { messages } from "../Components/KitForUI/PopMessages/messages";

export function isAccessTokenExpired() {
  const expiration = store.getters["user/tokenTime"];
  return expiration < Moment().unix();
}

export function createAxiosRequestInterceptor() {
  axios.interceptors.request.use((config) => {
    return new Promise(async (resolve, reject) => {
      // Do something before request is sent
      if ("Authorization" in config.headers) {
        const refreshingInStorage =
          localStorage.getItem("refreshing") === "false"
            ? false
            : !localStorage.getItem("refreshing")
            ? false
            : true;

        if (isAccessTokenExpired() && !refreshingInStorage) {
          localStorage.setItem("refreshing", true);

          await refreshJwtToken();

          localStorage.setItem("refreshing", false);

          resolve(config);
        } else {
          resolve(config);
        }
      } else {
        resolve(config);
      }
    });
  });
}

export function createAxiosResponseInterceptor() {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status, data } = error.response;

      if (status === 403 && data === "Re-Authenticate user!") {
        return messages.reAuthenticateUser();
      }

      return Promise.reject(error);
    }
  );
}
