export const state = { studies: [] };

export const mutations = {
  SET_STUDIES(state, studies) {
    state.studies = studies;
  },
};

export const getters = {
  stored_studies(state) {
    return state.studies;
  },
};

export const actions = {
  store_studies({ commit }, payload) {
    commit("SET_STUDIES", payload.studies);
  },
};
