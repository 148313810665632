import axios from "axios";
import { messages } from "@/Global/Components/KitForUI/PopMessages/messages.js";
import store from "@/store";

export function captchaAssessment(captchaToken, action) {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/public/recaptcha", {
        params: {
          captchaToken,
          action,
          siteKey: process.env.VUE_APP_GRECAPTCHA,
        },
      })
      .then((response) => {
        if (response.status === 202) {
          resolve(true);
        } else {
          messages.captchaTriggered();

          resolve(false);
        }
      })
      .catch((error) => {
        console.error(error);
        store.dispatch("layout/finish_loading");
        messages.generalError();
        reject(error);
      });
  });
}
