export const mutations = {
  ADD_FILTERS({ filters }, payload) {
    for (const [key, value] of Object.entries(payload)) {
      filters[key].push(value);
    }
  },
  REPLACE_FILTER({ filters }, payload) {
    filters[payload.key] = [payload.value];
  },
  REMOVE_FILTER({ filters }, payload) {
    filters[payload.key] = filters[payload.key].filter(
      (filter, index) => index !== payload.position
    );
  },
  RESET_FILTER({ filters }, payload) {
    filters[payload.key] = [];
  },
  NO_DATA({ filters }) {
    const stateKeys = Object.keys(filters);

    for (var i = 0; i < stateKeys.length; i++) {
      const key = stateKeys[i];
      if (key !== "is_lox_claim") {
        filters[key] = [];
      }
    }
  },
};
