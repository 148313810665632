export const state = {
  companies: [],
  credentials: null,
  documents: null,
  companyMembers: null,
  users: null,
  loxInvoices: null,
  generalForm: null,
  caseStudies: null,
  invoiceDetails: null,
  apiConfig: null,
};
