const jwt = require("jsonwebtoken");
const publicKEY = `
-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDS0JNs25txmIAJFdjxxOYIynYK
5O86tnGlIgfuNxCgu0YjsiZ+u33cVc5MK9eMJu9iB5hpg3jaRQPdpSENRm4JKuuY
TLRnc0TCsKFjU6HeJdOYk9cw6By2ItI0BrI1fAG5aSq75NBISltvTSMKMyFuQ1t6
VWIVTDmGJzRZJYDUWQIDAQAB
-----END PUBLIC KEY-----
`;
const verifyOptions = {
  issuer: "Lox Solution",
  audience: "https://www.loxsolution.com",
  expiresIn: "12h",
  algorithm: ["RS256"], // The difference witth signOptions is the this algorithm being in a array
};

export const state = {
  // We don't store the company and email in the local storage for safety reasons.
  // They will be retrieve thanks to the token.

  // Replace in order to remove quotes from string
  token: "",

  children_companies: [],
};

export const mutations = {
  SET_USER_TOKEN(state, token) {
    state.token = token;
  },
  UPDATE_USER_FROM_TOKEN(state) {
    const userDataDecoded = jwt.verify(
      state.token,
      publicKEY,
      verifyOptions
    ).payload;
    state.first_name = userDataDecoded.first_name;
    state.last_name = userDataDecoded.last_name;
    state.email_address = userDataDecoded.email_address;
    state.phone_number = userDataDecoded.phone_number;
    state.job_title = userDataDecoded.job_title;
    state.temporary_logged = userDataDecoded.temporary_logged;
    state.beta_access = userDataDecoded.beta_access;
    state.feature_list = userDataDecoded.feature_list?.features;
  },
  SET_USER(state, user) {
    // state.token = user.token;
    state.first_name = user.first_name;
    state.last_name = user.last_name;
    state.phone_number = user.phone_number;
    state.job_title = user.job_title;
  },
  SET_NAMES(state, user) {
    state.first_name = user.first_name;
    state.last_name = user.last_name;
  },
  NO_DATA(state) {
    state.token = null;
    state.first_name = "";
    state.last_name = "";
    state.phone_number = null;
    state.job_title = null;
    state.members = null;
    state.temporary_logged = null;
  },
  USER_EMAIL(state, payload) {
    state.email_address = payload.email;
  },
  SET_CHILDREN_COMPANIES(state, payload) {
    state.children_companies = payload.companies;
  },
};

function decodedTokenValue(token, key) {
  if (
    jwt.verify(token, publicKEY, {
      ignoreExpiration: true,
    })
  ) {
    const decodedToken = jwt.decode(token);

    return decodedToken.payload[key];
  }
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    const decodedToken = jwt.decode(state.token);

    if (!decodedToken) {
      return false;
    }

    return true;
  },
  tokenTime(state) {
    return jwt.decode(state.token).exp;
  },
  company(state) {
    return decodedTokenValue(state.token, "company");
  },
  isAdmin(state) {
    return decodedTokenValue(state.token, "admin");
  },
  isVerified(state) {
    return decodedTokenValue(state.token, "verified");
  },
  hasAccess(state) {
    return decodedTokenValue(state.token, "dashboard");
  },
  dataProcessed(state) {
    return decodedTokenValue(state.token, "access");
  },
  firstTime(state) {
    return decodedTokenValue(state.token, "firstTime");
  },
  userInfo(state) {
    return state;
  },
  email(state) {
    return state.email_address;
  },
  childrenCompanies(state) {
    return state.children_companies;
  },
};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  updateUserToken({ commit }, payload) {
    commit("SET_USER_TOKEN", payload.token);
  },
  updateUserFromToken({ commit }) {
    commit("UPDATE_USER_FROM_TOKEN");
  },
  updateUserInfo({ commit }, { user }) {
    commit("SET_USER", user);
  },
  updateUserNames({ commit }, user) {
    commit("SET_NAMES", user);
  },
  logout({ commit }) {
    commit("NO_DATA");
  },
  userEmail({ commit }, payload) {
    commit("USER_EMAIL", payload);
  },
  setChildrenCompanies({ commit }, payload) {
    commit("SET_CHILDREN_COMPANIES", payload);
  },
};
