export const getters = {
  adminCompanies(state) {
    return state.adminPanel.companies;
  },
  adminCredentials(state) {
    return state.adminPanel.credentials;
  },
  adminDocuments(state) {
    return state.adminPanel.documents;
  },
  adminMembers(state) {
    return state.adminPanel.companyMembers;
  },
  loxInvoices(state) {
    return state.adminPanel.loxInvoices;
  },
  adminUsers(state) {
    return state.adminPanel.users;
  },
  adminGeneralForm(state) {
    return state.adminPanel.generalForm;
  },
  adminCases(state) {
    return state.adminPanel.caseStudies;
  },
  adminInvoiceDetails(state) {
    return state.adminPanel.invoiceDetails;
  },
};
