import { state } from "./state.js";
import { mutations } from "./mutations.js";
import { actions } from "./actions.js";
import { getters } from "./getters.js";

export const filters = {
  state,
  mutations,
  actions,
  getters,
};
