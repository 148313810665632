import axios from "axios";
import store from "@/store";
import { messages } from "@/Global/Components/KitForUI/PopMessages/messages.js";

export function fetchMembers(fetchAfterUpdate = false) {
  return new Promise(function (resolve, reject) {
    const token = store.state.user.token;
    axios
      .get("/api/private/company/member", {
        params: { fetchAfterUpdate },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        let new_array = [];

        if (!response.data.members.length || !response.data.members[0].length) {
          store.dispatch("platform/injectMembers", new_array);
          return resolve(); // Resolve the promise here
        }

        response.data.members[0].forEach((member) => {
          if (member.email_address.split("@")[1] !== "loxsolution.com") {
            new_array.push(member);
          }
        });

        store.dispatch("platform/injectMembers", new_array);
        resolve();
      })
      .catch((error) => {
        console.error(error.message);
        messages.generalError();
        reject(error);
      });
  });
}

export function saveMember(userInfo) {
  return new Promise(function (resolve, reject) {
    const token = store.state.user.token;
    axios
      .post(
        "/api/private/company/member/add",
        {
          member: { ...userInfo },
          adminPage: false,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(async (response) => {
        let responseStatus = response.data.status;

        if (responseStatus === 202) {
          messages.memberSaved(userInfo);

          fetchMembers(true);
        } else if (responseStatus === 409) {
          messages.memberNotSaved(userInfo);
        }

        resolve();
      })
      .catch((error) => {
        console.error(error.message);
        messages.generalError();
        reject(error);
      });
  });
}

export function deleteMember(item) {
  return new Promise(function (resolve, reject) {
    const token = store.state.user.token;
    axios
      .post(
        "/api/private/company/member/delete",
        { email: item.email_address },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(() => {
        fetchMembers(true);

        messages.memberDeleted();

        resolve();
      })
      .catch((error) => {
        console.error(error);
        messages.generalError();
        reject(error);
      });
  });
}
