import { createI18n } from "vue-i18n";
import { ENGLISH_TRANSLATIONS } from "./en";
import { FRENCH_TRANSLATIONS } from "./fr";
import { numberFormats } from "./currency";

const TRANSLATIONS = {
  en: ENGLISH_TRANSLATIONS,
  fr: FRENCH_TRANSLATIONS,
};

const i18n = createI18n({
  locale: "en",
  messages: TRANSLATIONS,
  fallbackLocale: "en",
  silentFallbackWarn: true,
  silentTranslationWarn: false,
  numberFormats,
});

export default i18n;
