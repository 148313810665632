import axios from "axios";
import store from "@/store";
import router from "@/router";
import { messages } from "@/Global/Components/KitForUI/PopMessages/messages.js";
import { login } from "../../Login/Requests";

export function userRequestedPasswordReset(resetToken, next) {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/public/user/password/requested", {
        params: { reset_token: resetToken },
      })
      .then((response) => {
        resolve({
          valid: true,
          email: response.data.email,
        });
      })
      .catch((error) => {
        console.error(error);
        store.dispatch("layout/finish_loading");
        messages.accessDenied(next, "PasswordRequest");
        reject(error);
      });
  });
}

export function resetPassword(password, email) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/public/user/password/reset", {
        password,
        email,
      })
      .then(async (response) => {
        const user = response.data.user;

        await login(
          {
            email_address: user.email,
            password,
          },
          {
            loginPage: false,
          }
        );

        router.push("/home");

        resolve();
      })
      .catch((error) => {
        console.error(error);
        store.dispatch("layout/finish_loading");
        messages.generalError();
        reject(error);
      });
  });
}
