export const featureTours = {
  Home: [
    {
      title: {
        class: "center",
        text: "platform.updateTour.home.cardOne.title",
      },
      body: {
        class: "center",
        text: "platform.updateTour.home.cardOne.text",
      },
      footer: {
        class: "center",
        buttons: [
          {
            key: "Next",
            label: "cta.letsGo",
            class: "enabled",
          },
          {
            key: "Skip",
            label: "cta.skipTour",
            class: "disabled",
          },
        ],
      },
    },
    {
      id: "Sidebar",
      arrowDirection: "left",
      arrowPosition: "left top",
      title: {
        class: "left",
        text: "platform.updateTour.home.cardTwo.title",
      },
      body: {
        class: "left",
        text: "platform.updateTour.home.cardTwo.text",
      },
      footer: {
        class: "right",
        buttons: [
          {
            key: "Skip",
            label: "cta.skipTour",
            class: "disabled",
          },
          {
            key: "Next",
            label: "cta.next",
            class: "enabled",
          },
        ],
      },
    },
    {
      id: "IC-MyInvoices",
      arrowDirection: "left",
      arrowPosition: "left top",
      title: {
        class: "left",
        text: "platform.updateTour.home.cardThree.title",
      },
      body: {
        class: "left",
        text: "platform.updateTour.home.cardThree.text",
      },
      footer: {
        class: "right",
        buttons: [
          {
            key: "Redirect",
            label: "cta.seeNow",
            class: "disabled",
            route: "/invoiceControl/myInvoices",
          },
          {
            key: "Next",
            label: "cta.next",
            class: "enabled",
          },
        ],
      },
    },
    {
      id: "TopbarDropdown",
      arrowDirection: "top",
      arrowPosition: "top right",
      title: {
        class: "left",
        text: "platform.updateTour.home.cardFour.title",
      },
      body: {
        class: "left",
        text: "platform.updateTour.home.cardFour.text",
      },
      footer: {
        class: "right",
        buttons: [
          {
            key: "End",
            label: "cta.end",
            class: "enabled",
          },
        ],
      },
    },
  ],
  "Carrier Information": [
    {
      title: {
        class: "left",
        text: "platform.updateTour.carrierInformation.cardOne.title",
      },
      body: {
        class: "left",
        text: "platform.updateTour.carrierInformation.cardOne.text",
      },
      footer: {
        class: "right",
        buttons: [
          {
            key: "Skip",
            label: "cta.skipTour",
            class: "disabled",
          },
          {
            key: "Next",
            label: "cta.next",
            class: "enabled",
          },
        ],
      },
    },
    {
      id: "AddNewCarrier",
      arrowDirection: "left",
      arrowPosition: "left top",
      title: {
        class: "left",
        text: "platform.updateTour.carrierInformation.cardTwo.title",
      },
      body: {
        class: "left",
        text: "platform.updateTour.carrierInformation.cardTwo.text",
      },
      footer: {
        class: "right",
        buttons: [
          {
            key: "Skip",
            label: "cta.skipTour",
            class: "disabled",
          },
          {
            key: "Next",
            label: "cta.next",
            class: "enabled",
          },
        ],
      },
    },
    {
      id: "Carrier-0",
      arrowDirection: "left",
      arrowPosition: "left top",
      title: {
        class: "left",
        text: "platform.updateTour.carrierInformation.cardThree.title",
      },
      body: {
        class: "left",
        text: "platform.updateTour.carrierInformation.cardThree.text",
      },
      footer: {
        class: "right",
        buttons: [
          {
            key: "End",
            label: "cta.end",
            class: "enabled",
          },
        ],
      },
    },
  ],
  "Invoice Control - My Invoices": [
    {
      title: {
        class: "left",
        text: "platform.updateTour.myInvoices.cardOne.title",
      },
      body: {
        class: "left",
        text: "platform.updateTour.myInvoices.cardOne.text",
      },
      footer: {
        class: "right",
        buttons: [
          {
            key: "Skip",
            label: "cta.skipTour",
            class: "disabled",
          },
          {
            key: "Next",
            label: "cta.next",
            class: "enabled",
          },
        ],
      },
    },
    {
      id: "MoreIcon-0",
      arrowDirection: "top",
      arrowPosition: "top right",
      title: {
        class: "left",
        text: "platform.updateTour.myInvoices.cardTwo.title",
      },
      body: {
        class: "left",
        text: "platform.updateTour.myInvoices.cardTwo.text",
      },
      footer: {
        class: "right",
        buttons: [
          {
            key: "Skip",
            label: "cta.skipTour",
            class: "disabled",
          },
          {
            key: "Next",
            label: "cta.next",
            class: "enabled",
          },
        ],
      },
    },
    {
      id: "SavedAmount-0",
      arrowDirection: "top",
      arrowPosition: "top right",
      title: {
        class: "left",
        text: "platform.updateTour.myInvoices.cardThree.title",
      },
      body: {
        class: "left",
        text: "platform.updateTour.myInvoices.cardThree.text",
      },
      footer: {
        class: "right",
        buttons: [
          {
            key: "End",
            label: "cta.end",
            class: "enabled",
          },
        ],
      },
    },
  ],
};
