import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "btn-text"
};
import { useCaptcha } from "@/Global/Utils/composable.js";
export default {
  __name: 'Button',
  props: {
    clicked: {
      type: Function
    },
    label: {
      type: String,
      required: true
    },
    action: {
      type: String,
      default: "Clicked"
    },
    visibility: {
      type: Boolean,
      default: true
    },
    buttonClass: {
      type: String,
      required: true
    },
    component: {
      type: String,
      default: "none"
    }
  },
  emits: ["clicked"],
  setup(__props, {
    emit: __emit
  }) {
    const {
      verifyUserInteraction
    } = useCaptcha();
    const emit = __emit;
    const props = __props;
    const runFunction = async event => {
      const verified = await verifyUserInteraction(event, props.action);
      if (verified) {
        emit("clicked");
        if (props.clicked) props.clicked();
      }
    };
    const classChecker = (className, classWanted) => {
      return className === classWanted;
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["btn-2", {
          'hide-btn': !__props.visibility,
          enabled: classChecker(__props.buttonClass, 'enabled'),
          disabled: classChecker(__props.buttonClass, 'disabled'),
          'grey-style': classChecker(__props.buttonClass, 'grey-style'),
          'green-filled': classChecker(__props.buttonClass, 'green-filled'),
          'green-bordered': classChecker(__props.buttonClass, 'green-bordered'),
          'red-bordered': classChecker(__props.buttonClass, 'red-bordered'),
          'gradient-fill': classChecker(__props.buttonClass, 'gradient-fill'),
          'red-inlined': classChecker(__props.buttonClass, 'red-inlined')
        }]),
        onClick: runFunction
      }, [_createElementVNode("span", _hoisted_1, _toDisplayString(__props.label !== "" ? _ctx.$t(__props.label) : ""), 1)], 2);
    };
  }
};