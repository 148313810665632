import axios from "axios";
import { messages } from "@/Global/Components/KitForUI/PopMessages/messages.js";
import store from "@/store";
import router from "@/router";

function checkForRedirectionFromEmail(route) {
  const routeQuery = route.query;

  if (!!Object.keys(routeQuery).length) {
    const redirectToInvestigationDetails = routeQuery?.investigationDetails;
    const redirectToInvestigationHistory = routeQuery?.investigationHistory;

    if (!!redirectToInvestigationHistory) {
      const trackingNumber = routeQuery?.trackingNumber;

      return `/claimCenter/investigationDetails?investigationHistory=true&trackingNumber=${trackingNumber}`;
    } else if (!!redirectToInvestigationDetails) {
      return "/claimCenter/investigationDetails";
    }

    return null;
  }
}

export function login(form, { nextRoute, currentRoute }) {
  return new Promise(function (resolve, reject) {
    store.dispatch("user/logout");
    store.dispatch("platform/clearPlatform");

    axios
      .post("/api/public/login", form)
      .then(async (response) => {
        const token = response.data.token;
        //Populate User Store
        store.dispatch("user/updateUserToken", { token });
        store.dispatch("user/updateUserFromToken");

        const redirectionFromEmail = checkForRedirectionFromEmail(currentRoute);
        nextRoute = !!redirectionFromEmail ? redirectionFromEmail : nextRoute;

        const childrenCompanies = await companyHierarchy();

        store.dispatch("user/setChildrenCompanies", {
          companies: childrenCompanies.companies,
        });

        if (process.env.VUE_APP_DATABASE_SCOPE === "main") {
          const hasBetaAccess = store.getters["user/userInfo"].beta_access;

          if (hasBetaAccess || childrenCompanies.betaRedirection) {
            window.location.href = `https://www.beta.loxsolution.com/home?token=${token}`;
            return;
          }
        }

        await featureFetcher();

        router.push(nextRoute);

        resolve();
      })
      .catch((error) => {
        console.error(error.response);
        store.dispatch("layout/finish_loading");
        if (error.response.status === 405) {
          messages.passwordAuthenticationNotEnabled();
        } else {
          messages.loginFailed();
        }
        reject(error);
      });
  });
}
/**
 * Upon the user being successfully authenticated via Google
 * we then move to log the user in.
 * @param {String} email
 * The user's email coming from the response from Google
 * @param {Object} context
 * An object containing:
 * nextRoute = the route to send the user to upon successful login
 * currentRoute = the current route
 * @returns The user logged in on the page set to the nextRoute key in the context object.
 * - Errors:
 * - If any error occurs during the login process we pop the loginFailed
 * error message.
 */
export function googleLogin(email, uid, idToken, { nextRoute, currentRoute }) {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/public/user/google/login", {
        params: {
          email,
          token: idToken,
          uid,
        },
      })
      .then(async (response) => {
        const token = response.data.token;
        // Store the token, in the Session Storage
        store.dispatch("user/updateUserToken", { token });
        // Update the user information with the newly
        // created token
        store.dispatch("user/updateUserFromToken");

        const redirectionFromEmail = checkForRedirectionFromEmail(currentRoute);
        nextRoute = !!redirectionFromEmail ? redirectionFromEmail : nextRoute;

        const childrenCompanies = await companyHierarchy();

        store.dispatch("user/setChildrenCompanies", {
          companies: childrenCompanies.companies,
        });

        if (process.env.VUE_APP_DATABASE_SCOPE === "main") {
          const hasBetaAccess = store.getters["user/userInfo"].beta_access;

          if (hasBetaAccess || childrenCompanies.betaRedirection) {
            window.location.href = `https://www.beta.loxsolution.com/home?token=${token}`;
            return;
          }
        }
        // Fetch the Beta features
        await featureFetcher();
        // Redirect User to the Next Route
        router.push(nextRoute);

        resolve();
      })
      .catch((error) => {
        messages.loginFailed();
        reject(error);
      });
  });
}

export function companyHierarchy() {
  return new Promise((resolve, reject) => {
    const { token } = store.getters["user/userInfo"];

    axios
      .get("/api/private/company/hierarchy", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        resolve(response.data.companies);
      })
      .catch((error) => {
        console.error(error.message);
        messages.generalError();
        reject(error);
      });
  });
}

export function featureFetcher() {
  return new Promise((resolve, reject) => {
    const { token } = store.getters["user/userInfo"];

    axios
      .get("/api/private/features", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        store.dispatch("layout/set_new_features", {
          features: response.data.features,
        });

        resolve();
      })
      .catch((error) => {
        console.error(error.message);
        messages.generalError();
        reject(error);
      });
  });
}
