export const getters = {
  selections({ filters }) {
    let activeFilters = {};

    for (const [key, value] of Object.entries(filters)) {
      activeFilters = {
        ...activeFilters,
        ...(!!value.length && { [key]: value }),
      };
    }

    return activeFilters;
  },
  activeFilterCount({ filters }) {
    let count = -1;
    const filterValues = Object.values(filters);

    for (var i = 0; i < filterValues.length; i++) {
      const filterValue = filterValues[i];

      if (!!filterValue.length) {
        count += 1;
      }
    }

    return count;
  },
};
