import axios from "axios";
import { messages } from "@/Global/Components/KitForUI/PopMessages/messages.js";
import store from "@/store";

export function verifyEmail(form) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/api/public/user/verify", {
        email: form.email,
        first_name: form.firstName,
        last_name: form.lastName,
        company: form.company,
        job_title: form.jobTitle,
      })
      .then((status_code) => {
        if (status_code.status === 200) {
          resolve(true);
        }
      })
      .catch((error) => {
        console.error(error.response.status);
        store.dispatch("layout/finish_loading");

        if (error.response.status == 403) {
          messages.verifyEmailFailure();
        } else if (error.response.status == 409) {
          messages.emailExists();
        } else if (error.response.status == 400) {
          messages.informCompany();
        } else {
          messages.generalError();
        }
        reject(error);
      });
  });
}

export function emailVerified(code, form, registerObject) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/api/public/user/verified", {
        code,
        userData: form,
      })
      .then((response) => {
        if (response.status == 200) {
          messages.verifyEmailSuccess(form, registerObject);
        }

        resolve();
      })
      .catch((error) => {
        console.error(error);
        store.dispatch("layout/finish_loading");
        messages.retryCode(form, registerObject);
        reject(error);
      });
  });
}

export function register(form, registerPage, setEmptyForm) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/api/public/register", {
        ...form,
        registerPage,
      })
      .then((response) => {
        setEmptyForm();

        if (registerPage) {
          store.dispatch("platform/clearPlatform");
          location.href = "/onBoarding";
          const token = response.data.token;
          store.dispatch("user/updateUserToken", { token });
          store.dispatch("user/updateUserFromToken");
        } else {
          messages.successAdminRegister();
        }

        store.dispatch("layout/finish_loading");

        resolve();
      })
      .catch((error) => {
        console.error(error.response);
        store.dispatch("layout/finish_loading");

        if (error.response.status === 403) {
          messages.informCompany();
        } else {
          messages.generalError();
        }
        reject(error);
      });
  });
}
/**
 * Upon the user being successfully authenticated via Google
 * we then ask the user to fill in a few other details. We then
 * store this data in our Database and send the user to the on
 * boarding phase.
 * @param {Object} form
 * The form param should have the following information:
 * - email: {{ User Email }}
 * - firstName: {{ User First Name }}
 * - lastName: {{ User Last Name }}
 * - company: {{ User Company }}
 * - jobTitle: {{ User Job Title }}
 * - phone: {{ User Phone Number }}
 * @param {Function} initialiseForm
 * A function that sets the form back to its initial state
 * @returns If all goes successfully then the user's data
 * will be saved in our Database and the user will continue
 * with the on boarding flow.
 * - Errors:
 * - Using a company that is already registered pop
 * informCompany error message.
 * - Any other error pop registrationFailed error
 * message.
 */
export function googleRegistration(form, initialiseForm) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/public/user/google/register", { ...form })
      .then((response) => {
        // Clear platform of all previously stored data
        store.dispatch("platform/clearPlatform");
        // Redirect the user to the starty of the on
        // boarding flow.
        location.href = "/onBoarding";
        // Gather the jsonwebtoken created on our server
        const token = response.data.token;
        // Store the token, in the Session Storage
        store.dispatch("user/updateUserToken", { token });
        // Update the user information with the newly
        // created token
        store.dispatch("user/updateUserFromToken");
        // Stop the loading state
        store.dispatch("layout/finish_loading");

        resolve();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          return messages.informCompany(initialiseForm);
        } else {
          return messages.registrationFailed();
        }
      });
  });
}
/**
 * This request checks the validity of the user's email. We check that
 * the email isn't already documented in our Database.
 * @param {Object} form
 * The form param should have the key email with the value
 * being the user's email.
 * @returns If the email is valid or not.
 * If the email is valid expect a response stating - "Email verified".
 * - Errors:
 * - If the email exsists pop the registrationFailed error message. ( For
 * Registration Process)
 */
export function validateEmail(form, isRegisterFlow) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/public/user/email/validation", {
        form,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function sendSlackMessage(user) {
  axios.post("/api/public/slack", {
    message: `User didn't receive the code. Please can a team member get hold of ${user.firstName} ${user.lastName} on their email ${user.email}`,
  });
}
