export const actions = {
  // eslint-disable-next-line no-unused-vars
  add_filter({ commit }, payload) {
    commit("ADD_FILTERS", payload);
  },
  replace_filter({ commit }, payload) {
    commit("REPLACE_FILTER", payload);
  },
  remove_filter({ commit }, payload) {
    commit("REMOVE_FILTER", payload);
  },
  reset_filter({ commit }, payload) {
    commit("RESET_FILTER", payload);
  },
  no_data({ commit }) {
    commit("NO_DATA");
  },
};
