function formatNumber(valueToFormat) {
  // Ensure the input is treated as a string
  if (typeof valueToFormat !== "string") {
    valueToFormat = String(valueToFormat);
  }

  // Remove all commas
  let cleanedValue = valueToFormat.replace(/,/g, "");

  // Handle cases where commas were used as decimal separators
  const lastCommaIndex = cleanedValue.lastIndexOf(",");
  if (lastCommaIndex !== -1) {
    cleanedValue =
      cleanedValue.slice(0, lastCommaIndex) +
      "." +
      cleanedValue.slice(lastCommaIndex + 1);
  }

  // Remove any other dots that are not the last one
  const parts = cleanedValue.split(".");
  if (parts.length > 2) {
    cleanedValue = parts.slice(0, -1).join("") + "." + parts.slice(-1);
  }

  // Ensure the cleaned value is a valid number
  if (!isNaN(cleanedValue)) {
    return parseFloat(cleanedValue).toFixed(2);
  }

  // If the value cannot be converted to a number, return "NaN"
  return "NaN";
}

export function capitalize(value) {
  if (!value) {
    return "";
  }
  return (
    value.toString().charAt(0).toUpperCase() + value.slice(1).toLowerCase()
  );
}

export function uppercase(value) {
  if (!value) {
    return "";
  }
  return value.toString().toUpperCase();
}

export function lowercase(value) {
  if (!value) {
    return "";
  }
  return value.toString().toLowerCase();
}

export function currencyFormatter(value) {
  value = formatNumber(value);
  let currency = localStorage.getItem("currency");
  if (!currency) {
    console.error(
      "Currency value not found in localStorage. Using default 'EUR'."
    );
    currency = "EUR";
  }

  return parseFloat(value).toLocaleString("en-US", {
    style: "currency",
    currency,
  });
}

/**
 * Filter used to transform byte amounts to either megaBytes or kiloBytes depending on the size.
 */
export function bytesToMegaBytes(value) {
  // If value is 0 return 0
  if (!value) {
    return value;
  }
  // Divide the byte amount and round to two decimal places
  const megaBytes = (value / 1000000).toFixed(2);
  // If the megaByte amount is 0.00 rather show the kiloByte amount
  if (megaBytes === "0.00") {
    // Divide the byte amount and round to two decimal places
    const kiloBytes = (value / 100000).toFixed(2);
    // Add kb measurement to amount and return the value
    return kiloBytes + " kb";
  }
  // Add mb measurement to amount and return the value
  return megaBytes + " mb";
}

export function underscoreSplit(value) {
  return value.split("_").join(" ");
}
