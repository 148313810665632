import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createBootstrap } from "bootstrap-vue-next";
import Vuelidate from "vuelidate";
import VCalendar from "v-calendar";
import JsonCSV from "vue-json-csv";
import { createMetaManager } from "vue-meta";
import Multiselect from "vue-multiselect";
import VueCountryCode from "vue-country-code";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  createAxiosRequestInterceptor,
  createAxiosResponseInterceptor,
} from "@/Global/Utils/tokens.js";
import * as filters from "./Global/Utils/filters";

import "./Global/Styles/_predefinedVariables.scss";
import i18n from "./Global/Translations/i18n";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

const app = createApp(App);
app.use(store);
app.use(router);
app.use(i18n);
app.use(createBootstrap());
app.use(Vuelidate);
app.use(VCalendar);
app.component("DownloadCsv", JsonCSV);
app.component("MultiSelect", Multiselect);
app.use(VueCountryCode);
app.use(createMetaManager());
app.config.globalProperties.$filters = filters;

// app.config.productionTip = false;

const firebaseApp = initializeApp(firebaseConfig);
getAnalytics(firebaseApp);

createAxiosResponseInterceptor();
createAxiosRequestInterceptor();

router.isReady().then(() => {
  app.mount("#app");
});
