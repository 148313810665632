export const mutations = {
  SET_LOST_PACKAGES(state, payload) {
    state.claimCenter.packageInformation = {
      ...state.claimCenter.packageInformation,
      ...payload.packages,
    };
  },
  SET_LOST_PACKAGES_TOTAL(state, payload) {
    state.claimCenter.packageInformation.total = payload.total;
  },
  SET_LOST_DATA_PAGE(state) {
    state.claimCenter.packageInformation.pageNumber += 1;
  },
  SET_API_CONFIG(state, payload) {
    state.claimCenter.packageInformation.apiConfig = payload.config;
  },
  SET_LOST_INVOICES(state, payload) {
    state.claimCenter.packageInformation.existingInvoices = payload.fileNames;
  },
  SET_LOST_DATA_ACTIVITY(state, payload) {
    state.claimCenter.packageInformation.active = payload;
  },
};
