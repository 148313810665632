import store from "@/store";

export const state = {
  loading: false,
  sidebar: "Extended",
  sidebarDropdown: false,
  newFeatures: null,
  featureTour: false,
  carriersWithCredentials: null,
  allowRedirection: true,
  continueEditting: false,
};

export const mutations = {
  IS_LOADING(state) {
    state.loading = true;
  },
  FINISHED_LOADING(state) {
    state.loading = false;
  },
  COLLAPSE_SIDEBAR(state) {
    state.sidebar = "Collapsed";
  },
  EXTEND_SIDEBAR(state) {
    state.sidebar = "Extended";
  },
  TOGGLE_DROPDOWN(state) {
    state.sidebarDropdown = !state.sidebarDropdown;
  },
  ENGAGE_FEATURE_TOUR(state) {
    state.featureTour = true;
  },
  DISENGAGE_FEATURE_TOUR(state) {
    state.featureTour = false;
  },
  SET_NEW_FEATURES(state, payload) {
    state.newFeatures = payload.features;
  },
  SET_CARRIERS_WITH_CREDENTIALS(state, payload) {
    state.carriersWithCredentials = payload.carriers;
  },
  SET_ALLOW_REDIRECTION(state, payload) {
    state.allowRedirection = payload.redirection;
  },
  CONTINUE_EDITTING(state, payload) {
    state.continueEditting = payload;
  },
};

export const getters = {
  loading(state) {
    return state.loading;
  },
  sidebar(state) {
    return state.sidebar;
  },
  featureTour(state) {
    return state.featureTour;
  },
  featureList(state) {
    return state.newFeatures;
  },
  hasCarrierCredentials(state) {
    return store.getters["user/company"] === "Test"
      ? true
      : !!state.carriersWithCredentials?.length;
  },
  carriersWithCredentials(state) {
    if (store.getters["user/company"] === "Test") {
      return ["UPS", "Chronopost", "ColisPrive"];
    } else {
      return state.carriersWithCredentials;
    }
  },
  allowRedirection(state) {
    return state.allowRedirection;
  },
  continueEditting(state) {
    return state.continueEditting;
  },
};

export const actions = {
  start_loading({ commit }) {
    commit("IS_LOADING");
  },
  finish_loading({ commit }) {
    commit("FINISHED_LOADING");
  },
  extend_sidebar({ commit }) {
    commit("EXTEND_SIDEBAR");
  },
  collapse_sidebar({ commit }) {
    commit("COLLAPSE_SIDEBAR");
  },
  toggle_dropdown({ commit }) {
    commit("TOGGLE_DROPDOWN");
  },
  engage_feature_tour({ commit }) {
    commit("ENGAGE_FEATURE_TOUR");
  },
  disengage_feature_tour({ commit }) {
    commit("DISENGAGE_FEATURE_TOUR");
  },
  set_new_features({ commit }, payload) {
    commit("SET_NEW_FEATURES", payload);
  },
  set_carriers_with_credentials({ commit }, payload) {
    commit("SET_CARRIERS_WITH_CREDENTIALS", payload);
  },
  set_allow_redirection({ commit }, payload) {
    commit("SET_ALLOW_REDIRECTION", payload);
  },
  set_save_changes({ commit }, payload) {
    commit("SET_SAVE_CHANGES", payload);
  },
  continue_editting({ commit }, payload) {
    commit("CONTINUE_EDITTING", payload);
  },
};
