export const getters = {
  activeView(state) {
    return state.claimCenter.activeView;
  },
  filterRefundsCount(state) {
    return state.claimCenter.activeFilterCount;
  },
  columnToggle(state) {
    return state.claimCenter.columnToggle;
  },
  columns(state) {
    return state.claimCenter.columns;
  },
  dateRange(state) {
    return state.claimCenter.dateRange;
  },
  activeRefundsFilters(state) {
    return state.claimCenter.activeFilters;
  },
  refundsActiveDateRange(state) {
    return state.claimCenter.activeDateRange;
  },
  filteringRefundsData(state) {
    return state.claimCenter.filtered;
  },
  claimedBy(state) {
    return state.claimCenter.claimedBy;
  },
  claimToggle(state) {
    return state.claimCenter.claimToggle;
  },
};
